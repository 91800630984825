/*
 * App, global styles
 */
.App {
	text-align: center;
	font-family: 'Helvetica Neue', sans-serif;
	font-size: calc(12px + 1vmin);
	font-weight: bold;
	box-sizing: border-box;
	min-width: 768px;
	min-height: 900px;
	user-select: none;
}

button {
	font-family: 'Helvetica Neue', sans-serif;
	font-weight: 450;
	border: 1px solid black;
	border-radius: 8px;
	height: 40px;
	background-color: lightgrey;
}

/*
 * Messages, new game, join game, stats
 */
.messageWrapper {
	position: fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 100;
	background-color: rgba(0,0,0,0.3);
}
.messageBox, .statsBox, .newGameBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: lightyellow;
	color: black;
	border: 6px solid darkblue;
	border-radius: 20px;
	padding: 10px;
	user-select: text;
	font-weight: normal;
}

.newGameBox {
	width: 50%;
	min-height: 300px;
	min-width: 300px;
	max-width: 450px;
}
.newGameBox button, .newGameBox input {
	font-size: 90%;
}
.newGameBox .buttonArea button:first-child {
	margin-right: 20px;
}
.newGameBox .buttonArea button:last-child {
	margin-left: 20px;
}
.newGameBox td {
	text-align: left;
}

.messageBox {
	font-size: 85%;
	width: 50%;
	min-width: 150px;
	min-height: 150px;
	max-width: 500px;
	max-height: 900px;
}
.messageText {
	margin: 20px 10px;
	font-size: 150%;
}
.messageBox button {
	width: 40px;
	height: 40px;
}
.messageBox .buttonArea {
	margin-top: 10px;
	margin-bottom: 10px;
}

.statsBox {
	width: 50%;
	min-width: 150px;
	min-height: 150px;
	max-width: 400px;
	max-height: 600px;
	font-size: 85%;
}
.statsBox table {
	border-collapse: collapse;
	margin-bottom: 20px;
}
.statsBox td, .statsBox tr:last-child th {
	border: 1px solid black;
	padding: 1px 2px;
}
.statsBox button {
}
.scoreTable {
	margin-left: auto;
	margin-right: auto;
}
.scoreTable td, .scoreTable th {
	text-align: center;
}
.scoreTable td, .scoreTable th {
	padding-right: 10px;
}
.scoreTable th:nth-child(2), .scoreTable th:last-child,
.scoreTable td:nth-child(2), .scoreTable td:last-child {
	width: 4em;
}
.gameWinnerText, .roundWinnerText {
	font-weight: bold;
}
.gameWinnerText {
	font-size: 150%;
	color: red;
}
.justinImg {
	width: 80%;
}

/*
 * Arena, players, trains, initial tile
 */
.arena {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 50%;
	min-width: 768px;
	min-height: 420px;
	background-color: darkgreen;
}

.arenaHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	height: 60px;
	padding-top: 10px;
}
.arenaHeader div:first-child button {
	font-size: 80%;
}
.arenaHeader div:nth-child(2) {
	display: flex;
	align-items: center;
	justify-content: center;
}
.arenaHeader .tile {
	margin-left: 10px;
}
.trainStats {
	font-size: 90%;
}
.arenaHeader > div {
	margin-left: 10px;
	margin-right: 10px;
}
.arenaHeader .gameText {
	font-size: 80%;
	border-collapse: collapse;
}
.arenaHeader .gameText td:first-child {
	text-align: right;
}

.arenaPlayers {
	display: flex;
	margin-top: 10px;
	justify-content: space-around;
}
.player {
	font-size: 85%;
	height: 313px;
	min-width: 90px;
	max-width: 140px;
	margin-left: 5px;
	margin-right: 5px;
	flex-basis: 140px;
}
.playerText {
	font-weight: 450;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 122px;
	font-size: 15.5px;
	background-color: lightgrey;
	border: solid 2px black;
	border-radius: 10px
}
.player.mtrain .playerText {
	background-color: white;
	justify-content: flex-start;
}
.mTrainImg {
	margin-top: 10px;
	height: 80px;
}
.player.turn .playerText {
	border-color: red;
	background-color: white;
}
.playerText td {
}
.playerText td:first-child {
	text-align: left;
	padding-right: 6px;
}
.playerText div:last-child {
	text-align: center;
	padding: 0;
	height: 35px;
}
.playerName, .mTrain {
	text-align: center !important;
}
.playerTrain {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: calc(2*93px);
	background-color: transparent;
	overflow: hidden;
}
.playerTrain .wrapper {
	position: absolute;
	width: 48px;
	height: calc(3*93px);
}
.playerTrain .wrapper.showDrop {
	top: calc(2*-93px);
}
.playerTrain .wrapper.showTop1, .playerTrain .wrapper.showTop1A {
	top: -93px;
}
.playerTrain .wrapper.showTop1A {
	transition: top 1s;
}
.playerTrain .wrapper.showTop2 {
	top: 0;
}
.trainImg {
	transform: scale(-1,1);		/* flip the train to point to the right */
	height: 32px;
}
.chap {
	color: red;
}

/*
 * Hand, header, boneyard, rows
 */
.hand {
	position: fixed;
	top: 420px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: maroon;
}

.handHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	height: 40px;
}
.handHeader div:first-child button {
	margin-left: 10px;
}
.handHeader .nextPlayer {
	margin-left: 10px;
}
.handPoints {
	margin-right: 10px;
}

.draw {
	position: absolute;
	right: 20px;
	top: 40px;
	width: 90px;
	height: 200px;
	display:flex;
	flex-direction: column;
	align-items: center;
	font-weight: 450;
}
.draw button {
	width: 48px;
	height: 93px;
	padding: 2px;
	border-radius: 8px;
	background-color: white;
	border: solid 4px white;
	font-size: 13.8px;
}
.draw .canDraw {
	border: solid 4px lightgreen;
}
.boneyardEmpty button {
	background-color: lightgrey;
	border: solid 4px lightgrey;
}
.drawHeader, .drawMsg {
	color: white;
	margin: 6px;
}
.hand {
	min-width:760px;
}
@keyframes pulse {
	0% {color: lightgreen;}
	75% {color: lightgreen;}
	100% {color: maroon;}
}
.hand .turn {
	position: absolute;
	left: 0px;
	top: 35px;
	height: 20px;
	right: 110px;
	text-align: center;
	color: lightgreen;
	font-weight: bold;
	animation-name: pulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in;
}
.handRows {
	position: absolute;
	left: 0px;
	top: 50px;
	bottom: 0;
	right: 110px;
	overflow: scroll;
}

.handRow {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px;
}
.handRow .tile {
	display: inline-block;
	margin-left: 6px;
}
.handRow button {
	margin-left: 10px;
	padding: 2px;
	font-size: 130%;
	border-radius: 15px;
	width: 40px;
	height: 40px;
	border: none;
}

/*
 * Tiles
 */
.tile {
	border-radius: 8px;
	background-color: white;
	border: solid 4px transparent;
	font-weight: 500;
}
.tile.vertTile {
	width: 40px;
}
.tile.horizTile {
	width: 80px;
}
.tile.blankTile {
	background-color: grey;
}
.tile table {
	border-collapse: collapse;
	display: inline;
}
.tile table td {
	font-size: 24px;
	color: black;
	text-align: center;
	width: 40px;
	height: 40px;
}
.tile.vertTile tr:first-child td {
	border-bottom: solid 1px black;
}
.tile.horizTile tr:first-child td:first-child {
	border-right: solid 1px black;
}
.tile.blankTile td {
	color: transparent;
}
.tile.playable {
	border: solid 4px lightgreen;
}
.arena .tile.playable {
	border: solid 4px white;
}
.tile.selected {
	border-color: red;
}

/*
 * Help
 */
.help {
	position: fixed;
	border-radius: 10px;
	border: solid 4px maroon;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: lightyellow;
	font-size: 80%;
	overflow: scroll;
	padding: 10px;
}
.help h2 {
	font-size: 120%;
	margin-top: 10px;
	margin-bottom: 10px;
}
.help p {
	font-weight: normal;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 10px;
}
/*
 * Spinner
 */
@keyframes spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.tile {
	position: relative;			/* to allow absolute positioning within */
}
.horizTile .spinner {			/* don't know why, but this works */
	top: -30px;	
	left: -10px;
}
.vertTile .spinner {			/* don't know why, but this works */
	top: -8px;
	left: -30px;
}
.spinner {
	position: absolute;
	width: 100px !important;
	height: 100px !important;
	min-width: 100px;
	transform: scale(0.25);		/* use this to set the size! */
}
.spinner div {
	position: absolute;
	left: 44px;					/* 50% .spinner width - 50% spinDiv width */
	top: 0px;
	-webkit-animation: spinner linear 1s infinite;
	animation: spinner linear 1s infinite;
	background: #28292f;
	width: 12px;					/* spinDiv width */
	height: 24px;
	border-radius: 30%;
	transform-origin: 6px 50px;	/* 50% spinDiv width, 50% .spinner width */
}
.spinner div:nth-child(1) {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-animation-delay: -0.916666666666667s;
	animation-delay: -0.916666666666667s;
}
.spinner div:nth-child(2) {
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg);
	-webkit-animation-delay: -0.833333333333333s;
	animation-delay: -0.833333333333333s;
}
.spinner div:nth-child(3) {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg);
	-webkit-animation-delay: -0.75s;
	animation-delay: -0.75s;
}
.spinner div:nth-child(4) {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-animation-delay: -0.666666666666667s;
	animation-delay: -0.666666666666667s;
}
.spinner div:nth-child(5) {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg);
	-webkit-animation-delay: -0.583333333333333s;
	animation-delay: -0.583333333333333s;
}
.spinner div:nth-child(6) {
	-webkit-transform: rotate(150deg);
	transform: rotate(150deg);
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.spinner div:nth-child(7) {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-animation-delay: -0.416666666666667s;
	animation-delay: -0.416666666666667s;
}
.spinner div:nth-child(8) {
	-webkit-transform: rotate(210deg);
	transform: rotate(210deg);
	-webkit-animation-delay: -0.333333333333333s;
	animation-delay: -0.333333333333333s;
}
.spinner div:nth-child(9) {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg);
	-webkit-animation-delay: -0.25s;
	animation-delay: -0.25s;
}
.spinner div:nth-child(10) {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
	-webkit-animation-delay: -0.166666666666667s;
	animation-delay: -0.166666666666667s;
}
.spinner div:nth-child(11) {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg);
	-webkit-animation-delay: -0.083333333333333s;
	animation-delay: -0.083333333333333s;
}
.spinner div:nth-child(12) {
	-webkit-transform: rotate(330deg);
	transform: rotate(330deg);
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.spinner {
}

